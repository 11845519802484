body {
  margin: 0;
  min-height: 100vh;
  padding-top: 90px;
  background-color: $gray-100;
}

.navbar-text {
  font-size: 1.75rem;
}

.form-label {
  color: theme-color("primary");
  font-weight: 700;
}

.dv-star-rating-star {
  font-size: 3rem;
  margin-bottom: 0;
}

.ml-md-auto {
  @include media-breakpoint-up(md) {
    margin-left: auto !important;
    margin-right: initial !important;
  }
}
